import React from 'react';
import MainLayout from '../components/MainLayout';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../css/LegalPage.css';

/**
 * Leagal pages template
 */
class LegalPage extends React.Component {

	render() {
		return (
			<MainLayout scrollSnap={false} activeItem={-1} className="legalpage-container" footerClass="legalpage-footer">
				{/* TITLE */}
				<Row>
					<Col xs={{ span: 10, offset: 1 }}>
						<div className="emg-title">{this.props.pageContext.title}</div>
						<div
							className="emg-text"
							dangerouslySetInnerHTML={{
								__html: this.props.pageContext.text
							}}
						/>
					</Col>
				</Row>
				{/* PARAGRAPHS */}
				{this.props.pageContext.paragraphs.map((paragraph, index) => (
					<Row key={'legapage-row-paragraph-' + index} className="legalpage-row-paragraph">
						<Col xs={{ span: 10, offset: 1 }}>
							<div className="emg-subtitle">{paragraph.title}</div>
							<div
								className="emg-text"
								dangerouslySetInnerHTML={{
									__html: paragraph.text
								}}
							/>
						</Col>
					</Row>
				))}
				<Row className="legalpage-row-spacer" />
			</MainLayout>
		);
	}
}

export default LegalPage;
